@media only screen and (min-width: 520px) {
    .u-row {
    width: 510px !important;
    }
    .u-row .u-col {
    vertical-align: top;
    }

    .u-row .u-col-100 {
    width: 510px !important;
    }

}

@media (max-width: 520px) {
    .u-row-container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    }
    .u-row .u-col {
    min-width: 320px !important;
    max-width: 100% !important;
    display: block !important;
    }
    .u-row {
    width: 100% !important;
    }
    .u-col {
    width: 100% !important;
    }
    .u-col > div {
    margin: 0 auto;
    }
}

.ie-container table,
.ie-container tr,
.ie-container td {
    vertical-align: top;
    border-collapse: collapse;
}

p {
    margin: 0;
}

.ie-container table,
.mso-container table {
    table-layout: fixed;
}

* {
    line-height: inherit;
}

a[x-apple-data-detectors='true'] {
    color: inherit !important;
    text-decoration: none !important;
}

.ie-container table, td { color: #000000; } #u_body a { color: #0000ee; text-decoration: underline; } @media (max-width: 480px) { #u_content_button_1 .v-font-size { font-size: 14px !important; } }